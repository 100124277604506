import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
const Footerpage = () => {
  return (
    <> 
        <Navbar/>
        <Footer/>
    
    </>
  )
}

export default Footerpage