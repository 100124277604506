import React from 'react'

import Footer from '../components/Footer'
import Officeitems from '../components/Officeitems'

const Officeitempage = () => {
  return (
    <> 
    
    <Officeitems/>
    <Footer/>

    
    
    </>
  )
}

export default Officeitempage