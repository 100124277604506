import React, { useState, useEffect } from 'react';
import Data from './data';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';


const Slide = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === Data.length - 1 ? 0 : current + 1));
    };

    const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const goToNextSlide = () => {
    setCurrent((current) => (current === Data.length - 1 ? 0 : current + 1));
  };

  const goToPreviousSlide = () => {
    setCurrent((current) => (current === 0 ? Data.length - 1 : current - 1));
  };

  const goToSlide = (index) => {
    setCurrent(index);
  };

  if (!Array.isArray(Data) || Data.length === 0) {
    return null;
  }

  return (
    <div className="slider">
      {Data.map((slide, index) => {
        const isActive = index === current;

        return (
          <div
            key={index}
            onClick={() => goToSlide(index)}
            className={`slide ${isActive ? 'active' : ''}`}
          >
            {isActive && (
              <>
                <img src={slide.images} alt="Slide" />
                <div className="text">
                  <h1>{slide.message}</h1>
                </div>
              </>
            )}
          </div>
        );
      })}

      <div className="navigation-icons">
        <div className="prev" onClick={goToPreviousSlide}>
          <BsChevronCompactLeft size={30} />
        </div>
        <div className="next" onClick={goToNextSlide}>
          <BsChevronCompactRight size={30} />
        </div>
      </div>

      
    </div>
  );
};

export default Slide;
