import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import HeadlineCards from './components/HeadlineCards';
import Officeitems from './components/Officeitems';
import Category from './components/Category';
import Footer from './components/Footer';
function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <HeadlineCards/>
      <Officeitems />
      <Category/>
      <Footer/>
    </div>
  );
}

export default App;
