import React from 'react'
import Hero from '../components/Hero'


const Heropage = () => {
  return (
    <> 
    <Hero/>
   

    
    
    </>
  )
}

export default Heropage