export const data = [
    {
      id: 1,
      name: 'Cubiclips',
      category: 'Clip',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206416_sc7?wid=332&hei=332',
      price: '$$$$',
    },
    {
      id: 2,
      name: 'Print Copy',
      category: 'Print Copy',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp207769127_sc7?wid=120&hei=120',
      price: '$',
    },
    {
      id: 3,
      name: 'Print Copy',
      category: 'Print Copy',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp207769119_sc7?wid=120&hei=120',
      price: '$$',
    },
    {
      id: 4,
      name: 'Loaded Print Copy',
      category: 'Print Copy',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/m004089890_sc7?wid=120&hei=120',
      price: '$$$',
    },
    {
      id: 5,
      name: 'Printers',
      category: 'Printers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp212601465_sc7?wid=360&hei=360',
      price: '$$',
    },
    {
      id: 6,
      name: 'Hp Printer',
      category: 'Printers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/C81DA954-79F3-4A73-9BC8DF7F60930C2E_sc7?wid=360&hei=360',
      price: '$$$',
    },
    {
      id: 7,
      name: 'Hp Printer',
      category: 'Printers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp96531121_sc7?wid=360&hei=360',
      price: '$$$$',
    },
    {
      id: 8,
      name: 'Hp envy',
      category: 'Printers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/08CD20D7-740F-4047-85CA3F4E63137723_sc7?wid=360&hei=360',
      price: '$',
    },
    {
      id: 9,
      name: 'Clipboards',
      category: 'Clipboards',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206416_sc7?wid=332&hei=332',
      price: '$$',
    },
    {
      id: 10,
      name: 'Electric Clipboards',
      category: 'Clipboards',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206416_sc7?wid=332&hei=332',
      price: '$$$',
    },
    {
      id: 11,
      name: 'Aluminium Clipboards',
      category: 'Clipboards',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206541_sc7?wid=360&hei=360',
      price: '$$$$',
    },
    {
      id: 12,
      name: 'Plastic Clipboards',
      category: 'Clipboards',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206411_sc7?wid=360&hei=360',
      price: '$',
    },
    {
      id: 13,
      name: 'Documents holder',
      category: 'Desk Organizers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s0108767_sc7?wid=250&hei=250',
      price: '$$',
    },
    {
      id: 14,
      name: 'Pencil holder',
      category: 'Desk Organizers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1191715_sc7?wid=360&hei=360',
      price: '$$$$',
    },
    {
      id: 15,
      name: 'Letter tray',
      category: 'Desk Organizers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/D57049F0-B957-43EC-8F8E9B46CC60189C_sc7?wid=360&hei=360',
      price: '$',
    },
    {
      id: 16,
      name: 'Plastic incline Sorter',
      category: 'Desk Organizers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp47919180_sc7?wid=360&hei=360',
      price: '$$$',
    },
  ];
  
  export const categories = [
    {
      id: 1,
      name: 'Printers',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp212601465_sc7?wid=360&hei=360',
    },
    {
      id: 2,
      name: 'Clipboards',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206416_sc7?wid=332&hei=332',
    },
    {
      id: 3,
      name: 'Stationery Sales',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1215143_sc7?wid=360&hei=360',
    },
    {
      id: 4,
      name: 'Staples',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206394_sc7?wid=360&hei=360',
    },
    {
      id: 5,
      name: 'Scissors',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/15484B00-76AA-4A12-8F062BF0A3C890AA_sc7?wid=360&hei=360',
    },
    {
      id: 6,
      name: 'Office deals',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp212601465_sc7?wid=360&hei=360',
    },
    {
      id: 7,
      name: 'Printed Paper',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/sp212601465_sc7?wid=360&hei=360',
    },
    {
      id: 8,
      name: 'Copy Paper',
      image:
        'https://www.staples-3p.com/s7/is/image/Staples/s1206299_sc7?wid=360&hei=360',
    },
  ];
  
  const Data = [
    {
        images: "https://images.pexels.com/photos/8512120/pexels-photo-8512120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        message: "test1",
    },
    {
        images: "https://images.pexels.com/photos/6250861/pexels-photo-6250861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        message: "test2",
    },
    {
        images: "https://images.pexels.com/photos/6346613/pexels-photo-6346613.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        message: "test3",
    }
]

export default Data;









