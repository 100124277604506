import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Heropage from './routes/Heropage';
import Officeitempage from './routes/Officeitempage';
import Footerpage from './routes/Footerpage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='heropage' element={<Heropage />} />
      <Route path='officeitempage' element={<Officeitempage />} />
      <Route path='Footerpage' element={<Footerpage />} />
      
    </Routes>
  </BrowserRouter>
);
