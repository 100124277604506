import React from 'react'
import Data from '../data.js';
import Slide from '../Slide.js'
import '../Slide.css';


const Hero = () => {
  return (
    <>
    <Slide slides={Data} />
    </>
  )
}

export default Hero