import React from 'react';
import{data} from '../data.js';
import { useState } from 'react';

const Officeitems = () => {
   // console.log(data);
   const [Officeitem, setOfficeitem] = useState(data)
   const filterType =(category) => {
            setOfficeitem(
                data.filter((item) =>{
                    return item.category === category;
                })
            );
   };
   const filterPrice =(price) => {
    setOfficeitem(
        data.filter((item) =>{
            return item.price === price;
        })
    );
};
   
  return (
    <div className='max-w-[1640px] m-auto px-4 py-12'>
        <h1 className='text-orange-600 font-bold text-4xl text-center'>Top Office Items</h1>

        <div className='flex flex-col lg:flex-row justify-between'>

            <div>
                <p className='font-bold text-gray-700'> Filter Type</p>

                <div className='flex justify-between flex-wrap'>
                    <button onClick={()=>setOfficeitem(data)} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>All</button>
                    <button onClick={()=> filterType('Clip')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>Clip</button>
                    <button onClick={()=> filterType('Print Copy')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>Print Copy</button>
                    <button onClick={()=> filterType('Printers')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>Printers</button>
                    <button onClick={()=> filterType('Clipboards')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>Clipboards</button>
                    <button onClick={()=> filterType('Desk Organizers')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>Desk Organizers</button>
                    



                </div>
            </div>
            <div>
            <p className='font-bold text-gray-700'>Filter Price</p>

            
            <div className='flex justify-between max-w-[390px] w-full'> 
                <button onClick={()=> filterPrice('$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white' >$</button>
                <button onClick={()=> filterPrice('$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white' >$$</button>
                <button onClick={()=> filterPrice('$$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white' >$$$</button>
                <button onClick={()=> filterPrice('$$$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white' >$$$$</button> 
                <button onClick={()=> filterPrice('$$$$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white' >$$$$$</button>
            </div>
            </div>
        </div>


        <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>

            {Officeitem.map((item, index) =>(
                <div key={index} className='border shadow-lg rounded-lg hover:scale-105 duration-300'>
                    <img src={item.image} alt="{item.name}"
                    className='w-full h-[200px] object-cover rounded-t-lg' />
                    <div className='flex justify-between px-2 py-4'>
                        <p className='font-bold'>{item.name}</p>
                        <p>
                            <span className='bg-orange-500 text-white p-1 rounded-full'>{item.price}</span>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Officeitems