import React, { useState } from 'react';
import { AiFillHome, AiFillTag, AiOutlineClose, AiOutlineMenu, AiOutlineSearch } from 'react-icons/ai';
import { BsFillCartFill, BsFillInfoCircleFill } from 'react-icons/bs';
import { MdFavorite, MdContactMail } from 'react-icons/md';

import { Link } from 'react-router-dom';

const Navbar = () => {
    const [nav, setNav] = useState(false);

    return (
        <div name='top' className='max-w-[1640] mx-auto flex justify-between items-center p-4'>
            <div className='flex items-center'>
                <div onClick={() => setNav(!nav)} className='cursor-pointer'>
                    <AiOutlineMenu size={30} />
                </div>
                <h1 className='text-2xl sm:text-3xl lg:text-4xl px-2'>
                    Ideas
                </h1>
                <div className='lg:flex items-center bg-gray-200 rounded-full p-1 text-[14px]'>
                    <p className='bg-black text-white rounded-full p-2 mr-2'>Delivery</p>
                    <p className='p-2'>Pickup</p>
                </div>

                <div className='bg-gray-200 rounded-full flex items-center px-4 w-[200px] sm:w-[400px] lg:w-[500px] ml-12'>
                    <AiOutlineSearch size={20} className='mr-2' />
                    <input className='bg-transparent p-2 w-full focus:outline-none' type="text" placeholder='Search Items' />
                </div>

                <button className='bg-black text-white hidden md:flex items-center py-2 ml-4 rounded-full'> 
                    <BsFillCartFill size={20} className='mr-2' /> Cart
                </button>
            </div>

            <div className="ml-auto"> {/* Push the following buttons to the far right */}
                <button className='bg-black text-white hover:bg-blue-600 px-4 py-2 rounded-full'>Sign In</button>
                <button className='bg-black text-white hover.bg-green-600 px-4 py-2 rounded-full'>Sign Up</button>
            </div>

            {nav ? <div className='bg-black/80 fixed w-full h-screen z-10 top-0 left-0'></div> : ''}

            <div className={nav ? 'fixed top-0 left-0 w-500 h-screen bg-white z-10 duration-300' : 'fixed top-0 left-[-100%] h-screen bg-white z-10 duration-300'}>

                <AiOutlineClose
                    onClick={() => setNav(!nav)} size={30} className='absolute ml-20 top-4 cursor-pointer' />
                <h2 className='text-2xl p-4 mr-5'>Ideas</h2>
                <nav>
                    <ul>
                        <li className='text-xl py-4 flex'><AiFillHome size={25} className='mr-4' /><Link to='/'> Home </Link></li>
                        <li className='text-xl py-4 flex'><MdFavorite size={25} className='mr-4' /><Link to='Officeitempage'> Orders </Link></li>
                        <li className='text-xl py-4 flex'><MdContactMail size={25} className='mr-4' /> <Link to='Footerpage'>Contact </Link> </li>
                        <li className='text-xl py-4 flex'><AiFillTag size={25} className='mr-4' /> Promotions</li>

                        <li className='text-xl py-4 flex'><BsFillInfoCircleFill size={25} className='mr-4' /> About</li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
